import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";

import swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";

import { GiftService } from "@data/services/gift.service";
import { IGift } from "@interfaces/gift";
import { IDesignation } from "@interfaces/designation";
import { LoggerService } from "@core/services/logger.service";
import { HelperService } from "@core/services/helper.service";
import { ConstantsService } from "@core/services/constants.service";
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-gift-edit',
  templateUrl: './gift-edit.component.html',
  styleUrls: ['./gift-edit.component.css'],
  providers: [LoggerService]
})
export class GiftEditComponent implements OnInit {

  constructor(private formBuilder: UntypedFormBuilder,
              private giftService: GiftService,
              private router: Router,
              private route: ActivatedRoute,
              private logger: LoggerService,
              private spinnerService: NgxSpinnerService,
              private titleService: Title) {
    this.titleService.setTitle(`${ConstantsService.pageTitle} Gift Details`);
  }

  editForm: UntypedFormGroup;
  gift: IGift;
  errorMessage: string;
  designationName: string;
  amount: number;
  fundNumber: string;

  submitting: boolean = false;
  attempts: number = 0;



  /**
 * Cancel and return to the Review page
 */
  onCancel() {
    this.logger.debug(`gift-edit.component.onCancel`);
    this.router.navigateByUrl("/review");
    HelperService.goToTop();
  }


  /**
   * Attempt to add the gift to the cache
   */
  onSubmit() {
    this.logger.debug(`gift-edit.component.onSubmit`);
    this.submitting = true;

    // Check if form is valid
    if (!this.editForm.valid) {
      // Stop submission if form is not valid and increment counter
      this.attempts++;
      this.logger.debug(`gift-edit.component.onSubmit | attempts: ${this.attempts}`);
      return;
    }

    let gift: IGift = { amount: +this.editForm.get("amount").value, id: +this.editForm.get("giftId").value };
    let designation: IDesignation = <IDesignation>this.editForm.get("fundId").value;
    gift.designation = designation;

    this.logger.trace(`gift-edit.component.onSubmit | designation: `, designation);

    this.giftService.editGift(gift).then(
      data => {
        this.logger.trace(`gift-edit.component.onSubmit | editGift response: `, data);
        swal.fire({
          title: "Updated!",
          text: `Your gift for $${gift.amount} to ${this.designationName} has been saved`,
          icon: "success",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-swal-confirm-button'
          }
        });
        this.router.navigateByUrl("/review");
        HelperService.goToTop();
      });
  }

  /*** Nested component event listeners ***/

  /**
   * Catch amount changed event emitted by child component and set local amount value
   * @param amount value sent out by child form
   */
  onAmountChanged(amount: number) {
    this.logger.debug('gift-edit.component.onAmountChanged | amount:', amount);
    this.editForm.patchValue({ amount: amount });
  }

  /**
   * Catch fund selected by search results or fund selector
   * @param selectedFund
   */
  onFundSelected(selectedFund: IDesignation) {
    this.logger.trace('gift-edit.component.onFundSelected | selectedFund:', selectedFund);
    this.fundId.setValue(selectedFund);
    this.designationName = selectedFund.publicName;
  }

  ngOnInit() {
    this.logger.debug("gift-edit.component.ngOnInit");

    // Create form
    this.editForm = this.formBuilder.group({
      giftId: [],
      amount: ["", [Validators.required, Validators.min(1), Validators.max(99999)]],
      fundId: ["", Validators.required]
    });

    // Check url for parameter and load gift/fund if there
    const id = this.route.snapshot.paramMap.get('id');
    this.logger.debug(`gift-edit.component.ngOnInit | id: ${id}`);
    if (id) {
     // Retrieve gift
       this.giftService.getGift(+id).then(
         data => {
           // Set fund number
           this.fundNumber = data.designation.userId;
           // Set form values
           this.setFormValues(data);
           this.logger.trace(`gift-edit.component.ngOnInit | gift: `, data);
         },
         error => this.errorMessage = error);
    }

  }

  /**
 * Populate form data with Gift or Designation data returned from api
 * @param gift IGift used to populate form data
 */
  async setFormValues(gift: IGift) {
    this.logger.trace("gift-edit.component.setFormValues | gift:", gift);

    this.spinnerService.show(undefined, ConstantsService.defaultSpinnerOptions);

    // HACK: We're not going to hear back from the search to know whether or not it was successful
    // so we're just going to assume it was and move on

    this.spinnerService.hide();

    this.logger.debug(`gift-edit.component.setFormValues | Update form values`);

    this.editForm.patchValue({ giftId: gift.id });
    this.editForm.patchValue({ amount: gift.amount });
    this.amount = gift.amount;

    this.editForm.patchValue({ fundId: gift.designation });
    this.designationName = gift.designation.publicName;


  }

  get fundId() { return this.editForm.get("fundId"); }
  get amountControl() { return this.editForm.get("amount"); }

}
