<div class="container">
  <div class="col-12">
    <div class="pt-3">
      <p>
        What's better than giving to initiatives you care about? Doubling or tripling the impact of your generosity with a matching gift!
      </p>
      <p>
        Matching gift programs can dramatically increase your charitable giving to the university at no additional cost to you. Many companies match donations made by their employees (and sometimes even retirees or employees' spouses). This support helps UC San Diego enhance the student experience, enrich our campus community, provide compassionate patient care, and spark research and innovation.
      </p>
      <p>
        Check if an employer is a participating company and view its specific matching gift forms, guidelines, and instructions by using the search box below.
      </p>
      <div class="row">
        <div class="col-12 m-2">
          <app-matching-gift-search></app-matching-gift-search>
        </div>
      </div>
      <p class="pt-2">
        If employer information is not available using this search tool, please contact the company to see if it has a matching gift program. If required to submit a hard copy of a matching gift form, please mail it to the following address:
      </p>
    </div>
  </div>
</div>


