<div class="container-fluid">
  <div class="progress-meter-wrapper">
    <div class="progress-meter">
      <div class="track">
        <span [ngClass]="{
                  'progress' : true,
                  'progress-1' : progressStep === 1,
                  'progress-2' : progressStep === 2,
                  'progress-3' : progressStep === 3,
                  'progress-4' : progressStep === 4,
                  'progress-5' : progressStep === 5
                  }">
        </span>
      </div>
      <ul class="progress-points" data-current="1">
        <li [routerLink]="['/add-gift']"
            [ngClass]="{
                'progress-point' : true,
                'active' : progressStep === 1,
                'completed' : progressStep > 1,
                'enabled' : progressStep > 1 && progressStep !== 5
                }">
          <span>Gift Details</span>
        </li>
        <li [routerLink]="['/review']"
            [ngClass]="{
                'progress-point' : true,
                'active' : progressStep === 2,
                'completed' : progressStep > 2,
                'enabled' : progressStep > 2 && progressStep !== 5
                }">
          <span>Review</span>
        </li>
        <!-- TODO: how do i determine if i should route to payroll or credit card info here -->
        <li [routerLink]="['/your-info']"
            [ngClass]="{
                'progress-point' : true,
                'active' : progressStep === 3,
                'completed' : progressStep > 3,
                'enabled' : progressStep > 3 && progressStep !== 5
                }">
          <span>Your Info</span>
        </li>
        <li [ngClass]="{
                'progress-point' : true,
                'active' : progressStep === 4,
                'completed' : progressStep > 4
                }">
          <span>Payment</span>
        </li>
        <li [ngClass]="{
                'progress-point' : true,
                'completed' : progressStep === 5}">
          <span>Complete</span>
        </li>
      </ul>
    </div>
  </div>
</div>

