import { Injectable } from '@angular/core';
import {LoggerService} from "@core/services/logger.service";
import {HttpClient, HttpErrorResponse, HttpParams} from "@angular/common/http";
import {IRecaptchaVerificationResponse} from "@interfaces/recaptcha-verification-response";
import {catchError, tap} from "rxjs/operators";
import {ConstantsService} from "@core/services/constants.service";
import {AppConfigService} from "@core/services/app-config.service";
import {throwError} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class RecaptchaService {

  constructor(private readonly logger: LoggerService,
              private readonly http: HttpClient,
              private appConfigService: AppConfigService,
              private readonly constants: ConstantsService) { }

  apiUrl: string = this.appConfigService.config.webApi.url + "/recaptcha";

  /**
   * Verify recaptcha token
   * @param token
   */
  async verifyRecaptcha(token: string): Promise<IRecaptchaVerificationResponse> {
    this.logger.debug("recaptcha.service.verifyRecaptcha");
    this.logger.trace(`recaptcha.service.verifyRecaptcha | token: ${token}`);

    const url = `${this.apiUrl}/verify`;

    const payload = {
      token: token
    };

    this.logger.trace(`recaptcha.service.verifyRecaptcha | url: ${url}`);

    return this.http.post<IRecaptchaVerificationResponse>(url, payload, ConstantsService.httpOptions)
      .pipe(tap(data => {this.logger.trace('recaptcha.service.verifyRecaptcha | result: ', data)})
        , catchError(err => this.handleError(err)))
      .toPromise();
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';

    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }

    this.logger.error(`HttpError`, err);
    return throwError(errorMessage);
  }

}
