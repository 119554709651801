import { Component, OnInit } from '@angular/core';
import { FormBuilder , Validators} from "@angular/forms";
import { DonationService } from "@data/services/donation.service";
import { PaymentType } from "@interfaces/enums/payment-type.enum";
import { GiftType } from "@interfaces/enums/gift-type.enum";
import { LoggerService } from "@core/services/logger.service";

@Component({
  selector: 'app-select-payment-type',
  templateUrl: './select-payment-type.component.html',
  styleUrls: ['./select-payment-type.component.css'],
  providers: [LoggerService]
})
export class SelectPaymentTypeComponent implements OnInit {

  constructor(public readonly donationService: DonationService,
              private readonly formBuilder: FormBuilder,
              private readonly logger: LoggerService) {  }

  giftType!: GiftType;
  GiftType = GiftType;
  PaymentType = PaymentType;

  submitting: boolean = false;

  initialGiftTypeSet: boolean = false;

  form = this.formBuilder.group({
    paymentType: [-1, [Validators.required]]
  });

  get paymentType() { return this.form.get("paymentType"); }

  ngOnInit() {
    this.logger.debug(`select-payment-type.component.ngOnInit | giftType: ${this.donationService.giftType} | paymentType: ${this.donationService.paymentType}`)

    // Get initial value
    if (this.donationService.paymentType){
      this.paymentType.setValue(this.donationService.paymentType);
    }

    // Watch for changes and update the donation object
    this.paymentType.valueChanges.subscribe(value => {
      this.logger.debug(`select-payment-type.component.paymentType.valueChanges | value: ${value}`);
      this.donationService.paymentType = value;
    });

    // Watch for changes on the gift type, so we can set the default payment type
    this.donationService.giftType$.subscribe(giftType => {
      this.logger.debug(`select-payment-type.component.giftType$.subscribe | giftType: ${giftType} | initialGiftTypeSet: ${this.initialGiftTypeSet}`)
      this.giftType = giftType;
      if (this.initialGiftTypeSet){
        if (giftType === GiftType.OneTime || giftType === GiftType.PledgePayment){
          this.paymentType.setValue(PaymentType.UnifiedCheckout);
        } else {
          this.paymentType.setValue(undefined);
        }
      } else {
        this.initialGiftTypeSet = true;
      }
    });
  }

}
