import { Injectable } from '@angular/core';
import { State } from '@interfaces/state';
import {HttpHeaders} from "@angular/common/http";
import {AppConfigService} from "@core/services/app-config.service";

@Injectable({
  providedIn: 'root'
})
export class ConstantsService {

  constructor(private readonly appConfigService: AppConfigService) {  }

  static readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  /**
   * GiftType Options
   */
  static readonly espiGiftTypeNewGift: string = "2c89009e-4f48-48b8-9643-513ae330df17";
  static readonly espiGiftTypePledge: string = "988c5423-50a5-4b51-b78a-4f048f907a4d";
  static readonly espiGiftTypeRecurring: string = "ddff24b5-fa15-430c-8910-11cecd325c8b";

  /**
   * Attribute Codes
   */
  static readonly espiGiftInstructionsAttributeCode: string = "39048B32-ECBE-45FE-B56F-436BA0C3D962";
  static readonly espiGiftTypeAttributeCode: string = "6EBD0189-7718-4EE0-91D4-39DF28167B47";
  static readonly espiRecurringAttributeCode: string = "5FDA9BAF-06D3-44B2-B2D3-0FF797C4A8D1";


  /**
   * Error messages
   */
  static readonly noGiftsInCart: string = "No gifts found";
  static readonly maxLengthMessage: string = "Max length";
  static readonly invalidCharactersMessage: string = "Invalid characters";

  /**
   * Browser functionality
   */
    static readonly isIe = /msie\s|trident\//i.test(window.navigator.userAgent);

  /**
   * Page Title Prefix
   */
  static readonly pageTitle = "Give to UC San Diego | ";

  /**
   * Postal Code Value for no postal code
   */
  static readonly noPostalCodeVal = "Do Not Use";

  /**
   * State Value for no State/Province
   */
  static readonly noStateObj = new State("00000000-0000-0000-0000-000000000000", "No State", "XX", "");

  static  readonly unitedStatesGuid: string = "70fa9361-547e-4c98-a3cf-f655d36f8ecf";

  /**
   * Tribute text values
   */
  static readonly inHonorText = "in honor of";
  static readonly inMemoryText = "in memory of";

  /**
   * Field character limits
   */
  static readonly attributeTextLength: number = 255;
  static readonly cityCharacterLimit: number = 50;
  static readonly addressLinesCharacterLimit: number = 60;

  static readonly firstNameCharacterLimit: number = 30;
  static readonly lastNameCharacterLimit: number = 60;

  /**
   * Alert messages
   */
  static readonly processingErrorMessage: string = "An error occurred processing your request. Please contact Gift Processing at 858-246-1090 to continue your donation.";

  static readonly defaultSpinnerOptions: Object = {
    type: "ball-atom",
    size: "medium",
    bdColor: "rgba(51, 51, 51, 0.8)",
    color: "white",
    fullScreen: true
  };

  /*
  * Local Storage Keys
  * */
  // HACK: This is set from the config, but it is being accessed before the config is loaded so a default value is set
  localExpirySeconds: number = this.appConfigService.config.localStorage.expirySeconds;
  static readonly localExpiryKey: string = "expiry";
  static readonly localPaymentTypeKey: string = "paymentType";
  static readonly localGiftTypeKey: string = "giftType";
  static readonly localRecurrenceKey: string = "recurrence";
  static readonly localDonationIdKey: string = "donationId";
  static readonly localCommentsKey: string = "comments";
  static readonly localIsAnonymousKey: string = "isAnonymous";
  static readonly localTributeKey: string = "tribute";
  static readonly localAcknowledgeeKey: string = "acknowledgee";
  static readonly localDonorKey: string = "donor";
  static readonly localIsCorporateKey: string = "isCorporate";
  static readonly localGiftsKey: string = "gifts";
  static readonly localCountriesKey: string = "countries";
  static readonly localCountryKey: string = "country";

  /**
   * ReCaptcha Configs
   * */
  recaptchaSiteKey: string = this.appConfigService.config.recaptcha.siteKey;
  recaptchaThreshold: number = this.appConfigService.config.recaptcha.threshold;

}
