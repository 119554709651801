import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from "@angular/router";

import swal from "sweetalert2";
import { NgxSpinnerService } from "ngx-spinner";

import { GiftService } from "@data/services/gift.service";
import { IGift } from "@interfaces/gift";
import { LoggerService } from "@core/services/logger.service";
import { HelperService } from "@core/services/helper.service";

@Component({
  selector: 'app-gift-list',
  templateUrl: './gift-list.component.html',
  styleUrls: ['./gift-list.component.css']
})
export class GiftListComponent implements OnInit {

  constructor(private readonly giftService: GiftService,
              private readonly router: Router,
              private readonly spinnerService: NgxSpinnerService,
              private readonly logger: LoggerService) {

  }


  @Output() totalChanged = new EventEmitter<number>();

  pageTitle: string = 'You Have Selected The Following Gifts:';

  gifts: IGift[];
  total: number;
  errorMessage: string;


  /**
 * Route to Add Gift component
 */
  addGift(): void {
    this.logger.debug('gift-list.component.addGift');
    this.router.navigateByUrl("/add-gift");
    HelperService.goToTop();
  }

  /**
   * Calls the deleteGift method on the gift service and attempts to delete the gift based on id provided
   * @param giftId Id of the gift that should be deleted
   */
  async onDeleteGift(giftId: number): Promise<void> {
    this.logger.debug(`gift-list.component.onDeleteGift | giftId: ${giftId}`)
    const result = this.giftService.deleteGift(giftId);

    this.logger.trace(`gift-list.component.onDeleteGift | result: `, result);

    await swal.fire({
      title: "Deleted",
      text: "",
      icon: "success",
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-swal-confirm-button'
      }
    });

    await this.getGifts();
  }


  /**
   * Navigate to Edit component based on id provided
   * @param giftId Id of the gift that should be edited
   */
  onEditGift(giftId: number): void {
    this.logger.debug(`gift-list.component.onEditGift | giftId: ${giftId}`);
    this.router.navigate(["/edit-gift", giftId]);
    HelperService.goToTop();
  }


  /**
   * Attempts to retrieve cached gifts. If no cached gifts exists it will route to the AddGift component
   */
  async getGifts(): Promise<void> {
    const gifts = await this.giftService.getGifts().catch(err => {
      // HACK: Not sure if this is necessary since we're not making an api call anymore
      this.logger.error(`gift-list.component.getGifts`, err);
    });

    if (gifts && gifts.length >= 1) {
      this.logger.trace(`gift-list.component.getGifts | gifts: `, gifts);
      this.gifts = gifts;
      this.total = this.giftService.giftTotal();
      await this.spinnerService.hide();
    } else {
      await this.router.navigateByUrl("/add-gift");
    }

  }

  /**
   * Cancel of delete
   */
  onCancel(event): void {
    this.logger.debug(`gift-list.component.onCancel | event: `, event);
    swal.fire({
      title: "Cancelled",
      text: "",
      icon: "info",
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-swal-confirm-button'
      }
    });
  }

  ngOnInit() {
    this.logger.debug('gift-list.component.ngOnInit');
    this.getGifts();

  }
}

