import { Component, OnInit, Input } from '@angular/core';
import { LoggerService } from "@core/services/logger.service";
import { IDesignation } from "@interfaces/designation";
import { IAppealCode } from "@interfaces/appeal-code";

@Component({
  selector: 'app-direct-link',
  templateUrl: './direct-link.component.html',
  styleUrls: ['./direct-link.component.css'],
  providers: [LoggerService]
})
export class DirectLinkComponent implements OnInit {

  @Input() fund: IDesignation;
  @Input() appealCode: IAppealCode;

  constructor(private logger: LoggerService) {

  }

  /**
   * Copy the link text to the user's clipboard
   */
  copyClick(directLinkElement) {
    this.logger.debug(`direct-link.component.copyClick | directLinkElement: ${directLinkElement.value}`);

    this.logger.debug("LinkElement: ", directLinkElement.value);
    directLinkElement.select();
    document.execCommand('copy');
    directLinkElement.setSelectionRange(0, 0);

  }

  ngOnInit() {
    this.logger.debug(`direct-link.component.ngOnInit`);
  }

}
