import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService } from "@core/services/app-config.service";
import { IDesignation } from "@interfaces/designation";
import { LoggerService } from "@core/services/logger.service";

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor(private readonly http: HttpClient,
              private readonly appConfigService: AppConfigService,
              private readonly logger: LoggerService) {  }

  private apiUrl: string = this.appConfigService.config.webApi.url + "/fuzzysearch";


  async fuzzySearch(searchStr: string): Promise<IDesignation[]> {
    this.logger.debug('search.service.fuzzySearch | searchStr: ', searchStr);

    const options = { params: new HttpParams().set('id', searchStr) };

    return this.http.get<IDesignation[]>(this.apiUrl, options).toPromise();

  }

}
