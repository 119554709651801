<app-progress-bar [progressStep]="1"></app-progress-bar>

<div class="col-md-6 offset-md-3">
  <div class="d-flex">
    <h2 id="lblAboutYou" class="gray pb-2">Fund Search</h2>
  </div>
  <div class="" *ngIf="addForm">
    <form [formGroup]="addForm" (ngSubmit)="onSubmit()" (keydown.enter)="$event.preventDefault()">

      <app-fund-search (searchResultSelectionChanged)="onFundSelected($event)"></app-fund-search>

      <div class="alert alert-danger mt-md-1" *ngIf="fundId.invalid && (fundId.dirty || fundId.touched || submitting)">
        <div *ngIf="fundId.errors.required">A Fund must be selected</div>
      </div>

      <div class="pb-3 pt-3">
        <hr />
      </div>

      <div #amountTarget></div>

      <app-gift-amount (amountChanged)="onAmountChanged($event)" [amount]="-1"></app-gift-amount>

      <div class="alert alert-danger mt-md-1" *ngIf="amount.invalid && (amount.dirty || amount.touched || submitting)">
        <div *ngIf="amount.errors.required">A donation amount must be selected</div>
        <div *ngIf="amount.errors.min">Donation must be between $1 and $99,999</div>
        <div *ngIf="amount.errors.max">Donation must be between $1 and $99,999</div>
      </div>

      <div *ngIf="attempts >= 3">
        <app-validation-failure [form]="addForm"></app-validation-failure>
      </div>

      <div class="d-flex">
        <button id="btnSaveGift" type="submit" class="btn btn-navigation w-100 mt-3 mb-3">
          Add Gift to Basket
        </button>
      </div>
    </form>


    <div class="pt-3 intro">
      To explore more funds, please visit our <a href="https://giving.ucsd.edu" target="_blank" rel="noopener">giving site</a>.
    </div>
  </div>

</div>


