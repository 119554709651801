import { Injectable } from '@angular/core';
import {LoggerService} from "@core/services/logger.service";
import {ConstantsService} from "@core/services/constants.service";

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(private readonly logger: LoggerService,
              private readonly constants: ConstantsService) { }

  // Get a value from local storage
  getItem<T>(key: string): T {
    this.logger.trace(`localStorage.getItem(${key})`);

    // Check expiry before getting value
    this.checkExpiry();

    const value = localStorage.getItem(key);
    this.logger.trace(`localStorage.getItem | Value: ${value}`);
    if (!value || value === 'undefined'){
      return undefined
    } else {
      const result = JSON.parse(value) as T;
      this.logger.trace(`localStorage.getItem | Result: `, result);
      return result;
    }

  }

  // Set a value in local storage
  setItem<T>(key: string, value: T): void {
    this.logger.trace(`localStorage.setItem | Key: ${key} | Value: ${JSON.stringify(value)}`);

    if (!value) {
      this.logger.trace(`localStorage.setItem | Value is null. Setting to null.`);
      localStorage.setItem(key, null);
    }

    localStorage.setItem(key, JSON.stringify(value));

    // check total local storage size
    this.logger.trace(`localStorage size: ${JSON.stringify(localStorage).length / 1024 * 2} KB`);

  }

  // Remove a value from local storage
  removeItem(key: string): void {
    this.logger.trace(`localStorage.removeItem | Key: ${key}`);
    localStorage.removeItem(key);
  }

  // Clear all values from local storage
  clear(): void {
    this.logger.trace(`localStorage.clear()`);
    localStorage.clear();
  }

  checkExpiry(): void {
    // Check if an expiry value is set
    const expiryString = localStorage.getItem(ConstantsService.localExpiryKey);
    this.logger.trace(`local-storage.service.checkExpiry | Expiry: ${expiryString}`)
    if (expiryString){
      const expiry = new Date(expiryString);

      if (expiry) {
        // Check if the expiry date has passed
        const now = new Date(Date.now());
        this.logger.trace(`local-storage.service.checkExpiry | Expiry: ${expiry} | Now: ${now}`)
        if (expiry < now) {
          this.logger.trace(`local-storage.service.checkExpiry | Expiry has passed. Clear all values.`);
          // Clear all values
          this.clear();
        }
      }

    }
    // Set expiry
    let expiryDate = new Date();
    const expirySeconds = this.constants.localExpirySeconds;
    this.logger.trace(`local-storage.service.checkExpiry | Expiry Seconds: ${expirySeconds}`)
    expiryDate = new Date(expiryDate.getTime() + (expirySeconds * 1000));

    this.setItem(ConstantsService.localExpiryKey, expiryDate.toLocaleString());
  }

}
