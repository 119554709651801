
<strong>Direct Link:</strong>
<mat-form-field class="w-100" appearance="outline">
  <textarea matInput #directLink readonly rows="3"
            value="https://giveto.ucsd.edu/giving/home/{{ fund ? 'gift-referral/' + fund.id + '/' : '?appealId=' }}{{ appealCode ? appealCode.id : '' }}"
            ></textarea>
</mat-form-field>
<div class="d-flex">
  <button id="btnCopy" type="button" class="btn btn-navigation mt-2 mb-2 w-100" (click)="copyClick(directLink)">
    <i class="fa fa-copy"></i> Copy to Clipboard
  </button>
</div>
