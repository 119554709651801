import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppConfigService } from "@core/services/app-config.service";
import { LoggerService } from "@core/services/logger.service";
import { ICountry} from "@interfaces/country";
import { ICountryViewModel } from "@interfaces/country-view-model";
import {ConstantsService} from "@core/services/constants.service";
import {LocalStorageService} from "@core/services/local-storage.service";

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  constructor(private readonly http: HttpClient,
              private readonly appConfigService: AppConfigService,
              private readonly localStorage: LocalStorageService,
              private readonly logger: LoggerService) { }

  private countryApiUrl: string = this.appConfigService.config.webApi.url;

  /**
   * Get a list of countries from API
   */
  async getCountries(): Promise<ICountry[]> {
    this.logger.debug('country.service.getCountries');

    const countries = this.localStorage.getItem<ICountry[]>(ConstantsService.localCountriesKey);

    if (countries){
      this.logger.trace(`country.service | returning countries from local storage`)
      return countries;
    }

    return this.http.get<ICountry[]>(`${this.countryApiUrl}/country`)
      .pipe(tap(data => {
        this.logger.trace("countries.service | countries: ", data);
          this.localStorage.setItem(ConstantsService.localCountriesKey, data);
        }),
        catchError(err => this.handleError(err))
      ).toPromise();
  }

  /**
   * Retrieve a country view model containing a list of states, address
   * captions, and has state/zip information
   * @param id
   */
  async getCountry(id: string): Promise<ICountryViewModel> {
    this.logger.debug('country.service.getCountry | id: ', id);

    const country = this.localStorage.getItem<ICountryViewModel>(ConstantsService.localCountryKey);

    if (country?.country?.id === id){
      this.logger.trace(`country.service | returning country from local storage`)
      return country;
    }

    return this.http.get<ICountryViewModel>(`${this.countryApiUrl}/country/${id}`).pipe(tap(data => {
      this.logger.trace(`country.service.getCountry | data: `, data)
      this.localStorage.setItem(ConstantsService.localCountryKey, data);
    }), catchError(err => this.handleError(err))).toPromise();
  }


  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';

    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }

    this.logger.error(`HttpError`, err);
    return throwError(errorMessage);
  }

}



