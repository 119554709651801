import { Injectable } from '@angular/core';
import { IGift } from "@interfaces/gift";
import { LoggerService } from "@core/services/logger.service";
import {LocalStorageService} from "@core/services/local-storage.service";
import {ConstantsService} from "@core/services/constants.service";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class GiftService {

  constructor(private readonly localStorage: LocalStorageService,
              private readonly logger: LoggerService) {  }


  /** DO NOT read from this directly in HTML template. Subscribe to subject and set a local variable */
  get gifts(): IGift[] {
    return this.localStorage.getItem(ConstantsService.localGiftsKey)
  }
  set gifts(value: IGift[]) {
    this.localStorage.setItem(ConstantsService.localGiftsKey, value);
  }
  gifts$ = new BehaviorSubject<IGift[]>(this.gifts);

  /** DO NOT read from giftTotal directly in html. Subscribe to subject and set a local variable */
  giftTotal(){
    let gifts = this.gifts;
    if (gifts == null) {
      return 0;
    }
    let total = 0;
    gifts.forEach(g => {
      total += g.amount;
    });
    return total
  }
  giftTotal$ = new BehaviorSubject<number>(this.giftTotal());

  async getGifts(): Promise<IGift[]> {
    this.logger.debug(`gift.service.getGifts`)
    return Promise.resolve(this.gifts);
  }

  async getGift(id: number): Promise<IGift> {
    this.logger.debug(`gift.service.getGift: ${id}`)
    return new Promise(resolve => {
      const gift = this.gifts.find(g => g.id == id);
      this.logger.trace(`gift: `, gift);
      resolve(gift);
    });
  }

  async addGift(gift: IGift): Promise<number> {
    this.logger.debug(`gift.service.addGift:`, gift.designation);
    let gifts = this.gifts;
    if (gifts == null) {
      this.logger.debug("gift.service.addGift | No gifts found, creating new array");
      gifts = [];
    }
    // Get the next id
    let maxId = 0;
    gifts.forEach(g => {
      if (g.id > maxId) {
        maxId = g.id;
      }
    });
    gift.id = maxId + 1;

    gifts.push(gift);

    this.gifts = gifts;

    this.giftTotal$.next(this.giftTotal());

    return Promise.resolve(gift.id);

  }

  deleteGift(id: number): Promise<number> {
    this.logger.debug(`gift.service.deleteGift: ${id}`);
    let gifts = this.gifts;
    if (gifts == null) {
      this.logger.debug("gift.service.deleteGift | No gifts found, nothing to delete");
      return Promise.resolve(0);
    }
    const index = gifts.findIndex(g => g.id == id);
    if (index >= 0) {
      gifts.splice(index, 1);
    }
    this.gifts = gifts;

    this.giftTotal$.next(this.giftTotal());

    return Promise.resolve(id);

  }

  async editGift(gift: IGift): Promise<number> {
    this.logger.debug(`gift.service.editGift: ${gift.id}`);
    let gifts = this.gifts;
    if (gifts == null) {
      this.logger.debug("gift.service.editGift | No gifts found, nothing to edit");
      return Promise.resolve(0);
    }
    const index = gifts.findIndex(g => g.id == gift.id);
    if (index >= 0) {
      gifts[index] = gift;
    }
    this.gifts = gifts;

    this.giftTotal$.next(this.giftTotal());

    return Promise.resolve(gift.id);

  }

}
