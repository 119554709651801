import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

import { HelperService } from "@core/services/helper.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
})
export class AppComponent {
  title = 'app';
  showToTop: boolean = false;
  stickToFooter: boolean = false;
  topPosToStartShowing = 100;

  bottom: number;

  constructor(private spinnerService: NgxSpinnerService) { }

  @HostListener("window:scroll")
  checkScroll() {
    const scrollPosition = window.pageYOffset;
    const footerTop = document.getElementById("footer").offsetTop;

    this.showToTop = (scrollPosition >= this.topPosToStartShowing);
    this.stickToFooter = (scrollPosition + window.innerHeight >= footerTop);

    if (this.stickToFooter) {
      this.bottom = scrollPosition + window.innerHeight - footerTop;
    } else {
      this.bottom = 0;
    }

  }

  goToTop(): void {HelperService.goToTop();}

  ngOnInit(): void {
    // this.openSnackbar();
  }

}
