<mat-dialog-content [hidden]="!show">
  <div class="d-flex flex-column" >
    <div>
      <div class="row">
        <div id="buttonPaymentListContainer" class="w-100"></div>
        <div id="embeddedPaymentContainer" class="w-100"></div>
      </div>
    </div>
    <div class="row" *ngIf="giftType === GiftType.Recurring && recurrence">
      <p>By continuing, you authorize the storage of this payment method for donations of ${{giftTotal}} {{RecurrenceType[recurrence.frequency]}} starting {{recurrence.startDate | date: "shortDate"}}</p>
    </div>
    <div class="row">
      <button id="cancel" type="button" class="btn btn-navigation w-100 mt-3" (click)="onCancel()">
        <i class="fa fa-chevron-left"></i> Cancel
      </button>
    </div>
  </div>
</mat-dialog-content>
