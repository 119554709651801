import { Component, OnInit, Input } from '@angular/core';



@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrls: ['./progress-bar.component.css']
})
export class ProgressBarComponent implements OnInit {

  private _progressStep: number;

  @Input()
  set progressStep(step: number) {
    this._progressStep = step || 0;
  }

  get progressStep(): number { return this._progressStep; }

  constructor() { }

  ngOnInit() {
  }



}
