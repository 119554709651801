<div class="pb-2 pt-2">
  <!-- HACK: this label is overflowing page on mobile -->
  <mat-checkbox id="chkInHonorOrMemory" class="checkLabel" [(ngModel)]="inHonorOrMemory" (change)="onInTributeChanged($event)">
    I'd like to give this gift in memory or in honor of someone
  </mat-checkbox>
</div>

<div class="" *ngIf="inHonorOrMemory && form">
  <form [formGroup]="form">
    <div>
      <div class="btn-group d-flex">
        <label id="optionInHonorOf"
               [ngClass]="{
             'btn btn-selection w-100' : true,
             'active' : tributeType.value === inHonorOfText}"
               class="btn btn-selection w-100">
          <input type="radio" formControlName="tributeType" [value]="inHonorOfText" /> In Honor
        </label>
        <label id="optionInMemoryOf"
               [ngClass]="{
             'btn btn-selection w-100' : true,
             'active' : tributeType.value === inMemoryOfText}">
          <input type="radio" formControlName="tributeType" [value]="inMemoryOfText" required /> In Memory
        </label>
      </div>
    </div>
    <div class="alert alert-danger mt-md-1" *ngIf="tributeType.invalid && tributeType.errors.required && submitting">Please select either In Honor or In Memory</div>

    <div class="d-lg-flex mt-3">
      <div class="pb-1 flex-fill">
        <mat-form-field class="w-100 pr-lg-2" appearance="outline">
          <mat-label>First Name</mat-label>
          <input matInput id="tributeFirstName" formControlName="firstName" />
          <mat-error *ngIf="firstName.invalid && firstName.errors.required && (submitting || firstName.touched || firstName.dirty)">First Name is required</mat-error>
          <mat-error *ngIf="firstName.errors?.maxlength">{{ constants.maxLengthMessage }} is {{ constants.firstNameCharacterLimit }} characters</mat-error>
          <mat-error *ngIf="firstName.hasError('invalidCharacters')">{{constants.invalidCharactersMessage}}</mat-error>
        </mat-form-field>

      </div>
      <div class="flex-fill">
        <mat-form-field class="w-100 mt-lg-0 mt-4" appearance="outline">
          <mat-label>Last Name</mat-label>
          <input matInput id="tributeLastName" formControlName="lastName" />
          <mat-error *ngIf="lastName.invalid && lastName.errors.required && (submitting || lastName.touched || lastName.dirty)">Last Name is required</mat-error>
          <mat-error *ngIf="lastName.errors?.maxlength">{{ constants.maxLengthMessage }} is {{ constants.lastNameCharacterLimit }} characters</mat-error>
          <mat-error *ngIf="lastName.hasError('invalidCharacters')">{{constants.invalidCharactersMessage}}</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>

  <app-acknowledgee></app-acknowledgee>

</div>

