
import { NgModule, APP_INITIALIZER } from '@angular/core';

import { TitleCasePipe } from "@angular/common";
import { AppComponent } from './app.component';
import { SharedModule } from "@shared/shared.module";

import {NgxMaskDirective, provideNgxMask} from "ngx-mask";
import { NgxSpinnerModule } from "ngx-spinner";
import { LoggerModule, NgxLoggerLevel } from "ngx-logger";

import { AppConfigService } from "@core/services/app-config.service";
import {DataModule} from "@app/data/data.module";
import { InterfacesModule } from '@interfaces/interfaces.module';
import {FeaturesModule} from "@app/features/features.module";
import {CoreModule} from "@app/core/core.module";
import {NgxCaptchaModule} from "ngx-captcha";

export function appConfigFactory(provider: AppConfigService) {
  return () => provider.loadSettings();
}
@
NgModule({
  declarations: [
    AppComponent,

  ],
  imports: [

    NgxSpinnerModule,
    LoggerModule.forRoot({level: NgxLoggerLevel.TRACE}),
    SharedModule,
    DataModule,
    CoreModule,
    InterfacesModule,
    FeaturesModule,
    NgxMaskDirective,
    NgxCaptchaModule

  ],
  providers: [
    [TitleCasePipe],
    {
      provide: APP_INITIALIZER,
      useFactory: appConfigFactory,
      deps: [AppConfigService],
      multi: true
    },
    provideNgxMask()
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
