<div class="col-md-6 offset-md-3 mt-5">
  <div class="d-flex flex-column">
    <h1 class="blue pb-2">
      Select a fund to generate a direct link.
    </h1>

    <div class="" *ngIf="addForm">
      <form [formGroup]="addForm" (keydown.enter)="$event.preventDefault()">

        <app-fund-search (searchResultSelectionChanged)="onFundSelected($event)"
                         [showLink]=true>
        </app-fund-search>

        <div class="pb-3 pt-3">
          <hr />
        </div>

        <div class="search-separator">
          OR
        </div>

        <app-appeal-code [appealCodeOnly]="true">
        </app-appeal-code>

        <div><hr /></div>

      </form>
    </div>
  </div>
</div>
