import { Injectable } from '@angular/core';

import { HttpClient } from "@angular/common/http";

import { AppConfigService } from "@core/services/app-config.service";
import { ISsoUser } from "@interfaces/sso-user";
import {LoggerService} from "@core/services/logger.service";
import {tap} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class SsoUserService {

  constructor(private readonly http: HttpClient,
              private readonly logger: LoggerService,
              private readonly appConfigService: AppConfigService) { }

  private apiUrl: string = this.appConfigService.config.webApi.url + "/ssouser";


  /**
   * Retrieve SSO User from API and return
   */
  async getSsoUser(): Promise<ISsoUser> {
    this.logger.debug('sso-user.service.getSsoUser');

    return this.http.get<ISsoUser>(this.apiUrl)
      .pipe(tap(data => {this.logger.trace('sso-user.service.getSsoUser | data: ', data)}))
      .toPromise();
  }

}
