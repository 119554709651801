<app-progress-bar [progressStep]="3"></app-progress-bar>

<div class="col-md-6 offset-md-3">

  <div class="search-separator">About Your Gift</div>
  <div class="d-flex" *ngIf="gifts">
    <label class="w-50 font-weight-bold">Amount:</label><label class="w-100">${{ giftTotal }}</label>
  </div>
  <form #f="ngForm">
    <div class="font-weight-bold">
      Acknowledgement: *
    </div>
    <div>
      {{ acknowledgementText }}
    </div>
    <div class="pt-2 pb-2">
      <mat-checkbox id="chkAcknowledged" class="checkLabel" name="isAcknowledged" #acknowledged="ngModel" [(ngModel)]="isAcknowledged" required>I have read and understood the statement above</mat-checkbox>
    </div>
    <div class="">
      <label class="font-weight-bold">Payroll Cycle:</label>
    </div>
    <div class="btn-group d-flex">
      <label id="payrollCycleBiWeekly"
             [ngClass]=" {
             'btn btn-selection w-100' : true,
             'active' : payrollCycle === 'Bi-Weekly'}">
        <input type="radio" name="payrollCycle" #payrollCycleOptions="ngModel" [(ngModel)]="payrollCycle" value="Bi-Weekly" required /> Bi-Weekly
      </label>
      <label id="payrollCycleMonthly"
             [ngClass]=" {
             'btn btn-selection w-100' : true,
             'active' : payrollCycle === 'Monthly'}">
        <input type="radio" name="payrollCycle" #payrollCycleOptions="ngModel" [(ngModel)]="payrollCycle" value="Monthly" required /> Monthly
      </label>
    </div>

  </form>
  <div class="alert alert-danger" *ngIf="submitting && f.invalid">
    <div *ngIf="acknowledged.invalid && acknowledged.errors.required">Please acknowledge the statement above</div>
    <div *ngIf="payrollCycleOptions.invalid && payrollCycleOptions.errors.required">Please select a payroll cycle</div>
  </div>
  <div class="pt-3 d-flex">
    <label class="w-50 font-weight-bold">Payment Method:</label><label class="w-100">Payroll Deduction</label>
  </div>
  <div class="font-italic">
    {{ payrollNotice }}
  </div>
  <div class="search-separator pt-3">About You</div>
  <div *ngIf="donationService.ssoUser">
    <div class="d-flex">
      <label class="w-50 font-weight-bold">Name: </label><label class="w-100">{{ donationService.ssoUser.fullName }}</label>
    </div>
    <div class="d-flex">
      <label class="w-50 font-weight-bold">Email: </label><label class="w-100">{{ donationService.ssoUser.email }}</label>
    </div>
  </div>
  <div class="pt-3 pb-3 d-flex">
    <button type="button" id="btn-submit" class="btn btn-navigation w-100" (click)="submit()">
      <i class="fa fa-chevron-right"></i> Submit
    </button>
  </div>

</div>
