import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IAppConfig } from "@core/interfaces/app-config";
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {
    config!: IAppConfig;
    constructor(private readonly httpClient: HttpClient) { }

    loadSettings(): Observable<IAppConfig> {
      let jsonFile: string;
      const configFileName = environment.name === 'dev' ? "config.dev.json" : "config.deploy.json";

      // HACK: find a cleaner way to do this rather than a relative path
      jsonFile = `../bundles/assets/config/${configFileName}`;

      return this.httpClient.get<IAppConfig>(jsonFile)
        .pipe(tap(data => {this.config = data;}),
          catchError(err => this.handleError(err)));

    }


    private handleError(err: HttpErrorResponse) {
        let errorMessage = '';

        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        return throwError(errorMessage);
    }

}
