<div class="mt-1">
  <mat-checkbox id="chkAcknowledgee"
                class="checkLabel"
                (change)="onIsAcknowledgeeChanged($event)"
                [(ngModel)]="isAcknowledgee">
    Would you like UC San Diego Advancement to notify someone of your gift?
  </mat-checkbox>
</div>

<div class="" *ngIf="isAcknowledgee">
  <form [formGroup]="acknowledgeeForm">
    <div class="d-lg-flex mt-3">
      <div class="flex-fill mt-md-3">
        <mat-form-field class="w-100 pr-lg-2" appearance="outline">
          <mat-label>First Name</mat-label>
          <input matInput id="acknowledgeeFirstName" formControlName="firstName" #acknowledgeeFirstName />
          <mat-error *ngIf="firstName.errors?.required">First name is required</mat-error>
          <mat-error *ngIf="firstName.errors?.maxlength">{{ constants.maxLengthMessage }} is {{ constants.firstNameCharacterLimit }} characters</mat-error>
          <mat-error *ngIf="firstName.hasError('invalidCharacters')">{{constants.invalidCharactersMessage}}</mat-error>
        </mat-form-field>
      </div>
      <div class="flex-fill mt-md-3">
        <mat-form-field class="w-100 mt-lg-0 mt-4" appearance="outline">
          <mat-label>Last Name</mat-label>
          <input matInput id="acknowledgeeLastName" formControlName="lastName" #acknowledgeeLastName />
          <mat-error *ngIf="lastName.errors?.required">Last name is required</mat-error>
          <mat-error *ngIf="lastName.errors?.maxlength">{{ constants.maxLengthMessage }} is {{ constants.lastNameCharacterLimit }} characters</mat-error>
          <mat-error *ngIf="lastName.hasError('invalidCharacters')">{{constants.invalidCharactersMessage}}</mat-error>
        </mat-form-field>
      </div>
    </div>

    <app-address [form]="acknowledgeeForm"
                 *ngIf="showAddress">
    </app-address>
  </form>

</div>
