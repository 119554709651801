import { Injectable } from '@angular/core';
import {AppConfigService} from "@core/services/app-config.service";
import {LoggerService} from "@core/services/logger.service";
import {tap} from "rxjs/operators";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {IAuthorizationResponse} from "@interfaces/unified-checkout/authorization-response";
import {Donor} from "@interfaces/donor";
import {IBbRecurrence} from "@interfaces/blackbaud-donation/bb-recurrence";
import {ICaptureContextResponse} from "@interfaces/unified-checkout/capture-context-response";

@Injectable({
  providedIn: 'root'
})
export class UnifiedCheckoutService {

  constructor(private readonly logger: LoggerService,
              private readonly appConfigService: AppConfigService,
              private readonly http: HttpClient) {  }

  apiUrl: string = this.appConfigService.config.webApi.url + "/unifiedcheckout";
  token: string = this.appConfigService.config.webApi.token;

  private httpOptions: { headers: HttpHeaders } = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + this.appConfigService.config.webApi.token
    })
  };

  // Get capture context
  async getCaptureContext(merchantId: string,
                          amount: string,
                          streetAddress: string,
                          city: string,
                          state: string,
                          country: string,
                          postalCode: string,
                          firstName: string,
                          lastName: string,
                          email: string,
                          phone: string): Promise<ICaptureContextResponse> {

    this.logger.debug(`unifiedCheckoutService.getCaptureContext(${merchantId}, ${amount}, ${streetAddress}, ${city}, ${state}, ${country}, ${postalCode}, ${firstName}, ${lastName}, ${email}, ${phone}`);

    const payload = {
      merchantId: merchantId,
      amount: amount,
      streetAddress: streetAddress,
      city: city,
      state: state,
      country: country,
      postalCode: postalCode,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone
    }

    this.logger.trace(`unifiedCheckoutService.getCaptureContext | httpOptions:`, this.httpOptions);

    return  this.http.post<ICaptureContextResponse>(this.apiUrl + '/capturecontext', payload, this.httpOptions)
      .pipe(tap(data => this.logger.trace('unified-checkout.service.getCaptureContext | result: ', data)))
      .toPromise();

  }

  // Authorize Payment
  async authorizePayment(transientToken: string,
                         merchantId: string,
                         streetAddress: string,
                         city: string,
                         state: string,
                         country: string,
                         postalCode: string,
                         firstName: string,
                         lastName: string,
                         email: string,
                         phone: string): Promise<IAuthorizationResponse> {
    this.logger.debug(`unifiedCheckoutService.authorizePayment | transientToken: ${transientToken} | merchantId: ${merchantId} | streetAddress: ${streetAddress} | city: ${city} | state: ${state} | country: ${country} | postalCode: ${postalCode} | firstName: ${firstName} | lastName: ${lastName} | email: ${email} | phone: ${phone}`);

    const payload = {
      transientToken: transientToken,
      merchantId: merchantId,
      streetAddress: streetAddress,
      city: city,
      state: state,
      country: country,
      postalCode: postalCode,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone
    }

    return this.http.post<IAuthorizationResponse>(this.apiUrl + '/authorizepayment', payload, this.httpOptions)
      .pipe(tap(data => this.logger.trace('unified-checkout.service.authorizePayment | data: ', data)))
      .toPromise();

  }

  // Create Customer Token
  async createToken(transientToken: string,
                    merchantId: string,
                    streetAddress: string,
                    city: string,
                    state: string,
                    country: string,
                    postalCode: string,
                    firstName: string,
                    lastName: string,
                    email: string,
                    phone: string): Promise<IAuthorizationResponse> {
    this.logger.debug(`unifiedCheckoutService.createToken | transientToken: ${transientToken} | merchantId: ${merchantId} | streetAddress: ${streetAddress} | city: ${city} | state: ${state} | country: ${country} | postalCode: ${postalCode} | firstName: ${firstName} | lastName: ${lastName} | email: ${email} | phone: ${phone}` );

    const payload = {
      merchantId: merchantId,
      transientToken: transientToken,
      streetAddress: streetAddress,
      city: city,
      state: state,
      country: country,
      postalCode: postalCode,
      firstName: firstName,
      lastName: lastName,
      email: email,
      phone: phone
    }

    return this.http.post<IAuthorizationResponse>(this.apiUrl + '/createToken', payload, this.httpOptions)
      .pipe(tap(data => this.logger.trace("unified-checkout.service.createToken | result:", data)))
      .toPromise();


  }

  // Create Plan
  async createPlan(donor: Donor, amount: string, recurrence: IBbRecurrence, merchantId: string): Promise<IAuthorizationResponse> {
    this.logger.debug(`unified-checkout.service.createPlan | donor: ${JSON.stringify(donor)} | amount: ${amount} | recurrence: ${JSON.stringify(recurrence)} | merchantId: ${merchantId}`);

    const payload = {
      donor: donor,
      amount: amount,
      recurrence: recurrence,
      merchantId: merchantId
    }

    return this.http.post<IAuthorizationResponse>(this.apiUrl + '/createPlan', payload, this.httpOptions)
      .pipe(tap(data => this.logger.debug("unified-checkout.service.createPlan | result:", data)))
      .toPromise();

  }

  // Create Subscription
  async createSubscription(customerToken: string, planId: string, donor: Donor, recurrence: IBbRecurrence, merchantId: string): Promise<IAuthorizationResponse> {
    this.logger.debug(`unifiedCheckoutService.createSubscription | customerToken: ${customerToken} | planId: ${planId} | donor: ${JSON.stringify(donor)} | recurrence: ${JSON.stringify(recurrence)} | merchantId: ${merchantId}`);

    const payload = {
      customerToken: customerToken,
      planId: planId,
      donor: donor,
      recurrence: recurrence,
      merchantId: merchantId
    }

    return this.http.post<IAuthorizationResponse>(this.apiUrl + '/createSubscription', payload, this.httpOptions)
      .pipe(tap(data => this.logger.debug("unified-checkout.service.createSubscription | result:", data)))
      .toPromise();

  }

}
