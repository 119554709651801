import { IError } from "./error";


export interface IControlError {
  controlName: string;
  error: IError;
}


export class ControlError implements IControlError{
  controlName: string;
  error: IError;

  constructor(controlName: string, errorKey: string, errorValue: string) {
    this.controlName = controlName;
    this.error = { key: errorKey, value: errorValue };
  }

  public toString = (): string => {
    var result: string;
    switch (this.error.key) {
      case "required":
        {
          result = `${this.controlName} is a required field`;
        break;
        }
      case "min":
      {
          result = `${this.controlName} has a minimum value of ${this.error.value}`;
          break;
      }
      default:
      {
          result = `Field: ${this.controlName} | Error: ${this.error.key} | Message: ${this.error.value}`;
      }
    }
    return result;
  }

}
