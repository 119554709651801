import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {  throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { AppConfigService } from "@core/services/app-config.service"
import { LoggerService } from "@core/services/logger.service"
import { IAppealCode } from "@interfaces/appeal-code";
import {ConstantsService} from "@core/services/constants.service";

@Injectable({
  providedIn: 'root'
})
export class AppealCodeService {
  constructor(private readonly http: HttpClient,
              private readonly appConfigService: AppConfigService,
              private readonly logger: LoggerService) {  }

  private apiUrl: string = this.appConfigService.config.webApi.url + "/appealcode";


  /**
   * Retrieve appeal codes from web api
   */
  async getAppealCodes(): Promise<IAppealCode[]> {
    this.logger.debug('appeal-code.service.getAppealCodes');

    return this.http.get<IAppealCode[]>(this.apiUrl, ConstantsService.httpOptions).pipe(tap(data => {
        this.logger.trace('appeal-code.service.getAppealCodes | data: ', data);
      }), catchError(err => this.handleError(err))).toPromise();
  }

  private handleError(err: HttpErrorResponse) {
    let errorMessage = '';

    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }

    this.logger.error("Http error: ", err);
    this.logger.error(errorMessage);

    return throwError(err);
  }

}
