<div class="form-group" *ngIf="giftType === GiftType.Recurring && paymentType === PaymentType.UnifiedCheckout">
  <span class="less-emphasized-text">
    {{"Charge $" + total + " To My Account"}}
  </span>
  <form [formGroup]="form">
    <div class="w-100 mt-3">
      <mat-form-field class="w-100" appearance="outline">
        <mat-label>Frequency</mat-label>
        <mat-select id="frequency" formControlName="frequency">
          <mat-option *ngFor="let recurrenceOption of recurrenceOptions" [value]="recurrenceOption">
            {{RecurrenceType[recurrenceOption]}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.controls.frequency.errors?.required && form.controls.frequency.touched">Frequency is required</mat-error>
      </mat-form-field>
    </div>

    <div class="d-flex">
      <div class="w-100 form-group" *ngIf="giftType === GiftType.Recurring && frequency.value != null && paymentType !== PaymentType.PayrollDeduction">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Start Date</mat-label>
          <input matInput [matDatepicker]="picker" [min]="minDate"
                 placeholder="Start date"
                 id="startDate"
                 name="startDate"
                 formControlName="startDate" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </div>
  </form>

  <div class="info-box" *ngIf="giftType === GiftType.Recurring && paymentType === PaymentType.UnifiedCheckout">
    <span id="recurringInfo" [innerHtml]="recurringInfo"></span>
  </div>
  <div id="payrollDeductionInfo" class="info-box" *ngIf="paymentType === PaymentType.PayrollDeduction" [innerHtml]="payrollDeductionInfo">
  </div>

</div>



