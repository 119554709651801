<footer id="footer" class="wrap-footer pt-2 pt-md-5 pb-2 pb-md-3 mt-5 small">
  <div class="container-fluid text-center text-md-left">
    <div class="row">
      <div class="col-md-4 offset-md-1 mt-md-0 mt-3">
        <h5 class="text-uppercase">Other Ways To Give</h5>
        <ul class="list-unstyled">
          <li><a class="link-unstyled" href="http://giftplanning.ucsd.edu/">Planned Gifts</a></li>
          <li><a class="link-unstyled" href="https://crowdsurf.ucsd.edu/">Crowdsurf</a></li>
          <li><a class="link-unstyled" [routerLink]="['/matching-gift']">Matching Gifts</a></li>
          <li><a class="link-unstyled" href="http://giving.ucsd.edu/ways/index.html">Find Out More</a></li>
        </ul>
      </div>
      <hr class="clearfix w-100 d-md-none pb-3" />
      <div class="col-md-4 mb-md-0 mb-1">
        <h5 class="text-uppercase">Mailing Address:</h5>
        <p>9500 Gilman Drive #0940<br />La Jolla, CA 92093-0940</p>
        <p class="checkLabel">858.534.1610 | <a class="link-unstyled" href="mailto:giving@ucsd.edu">giving&#64;ucsd.edu</a></p>
      </div>
      <div class="col-md-3 d-none d-md-block">
        <a href="http://giving.ucsd.edu/"><img src="../../giving/bundles/assets/images/ucsd-logo.png" width="150px" alt="Give to UC San Diego" /></a>
        <p class="mt-3 checkLabel">
          <a class="link-unstyled" href="https://ucsd.edu/about/website-accessibility.html" target="_blank" rel="noopener">Accessibility</a> | <a class="link-unstyled" href="https://giving.ucsd.edu/advancement-privacy-policy.html" target="_blank" rel="noopener">Privacy Policy</a>
        </p>
      </div>
    </div>
  </div>
</footer>
<div class="copyright-bar pt-1 mb-1 mt-1 text-center small">
  <p>
    &copy;{{currentYear }} Regents of the University of California. All rights reserved.
  </p>
</div>
