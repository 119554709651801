import { Address } from "@interfaces/address";

export interface IDonor {
  FirstName: string;
  lastName: string;
  organizationName?: string;
  emailAddress?: string;
  phone?: string;
  address: Address;
}

export class Donor implements IDonor {
  FirstName: string;
  lastName: string;
  organizationName: string;
  emailAddress: string;
  phone: string;
  address: Address;


  public constructor(init?: Partial<Donor>) {
    Object.assign(this, init);
  }

}


