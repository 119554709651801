import {Component, EventEmitter, OnInit, Output, Renderer2} from '@angular/core';
import {LoggerService} from "@core/services/logger.service";
import {DonationService} from "@data/services/donation.service";
import {UnifiedCheckoutService} from "@data/services/unified-checkout.service";
import {MatDialogRef} from "@angular/material/dialog";
import {NgxSpinnerService} from "ngx-spinner";
import {ConstantsService} from "@core/services/constants.service";
import {IAuthorizationResponse} from "@interfaces/unified-checkout/authorization-response";
import {GiftType} from "@interfaces/enums/gift-type.enum";
import Swal from "sweetalert2";
import {ICaptureContextResponse} from "@interfaces/unified-checkout/capture-context-response";
import {ScriptService} from "@core/services/script.service";
import {GiftService} from "@data/services/gift.service";
import {DonorService} from "@data/services/donor.service";
import {IBbRecurrence} from "@interfaces/blackbaud-donation/bb-recurrence";
import {RecurrenceType} from "@interfaces/enums/recurrence-type.enum";

// Declare JavaScript function for Unified Checkout
declare function Accept(cc: string): any;

@Component({
  selector: 'app-unified-checkout',
  standalone: false,
  templateUrl: './unified-checkout.component.html',
  styleUrl: './unified-checkout.component.css'
})
export class UnifiedCheckoutComponent implements OnInit {
  constructor(private readonly logger: LoggerService,
              private readonly giftService: GiftService,
              private readonly donationService: DonationService,
              private readonly donorService: DonorService,
              private readonly unifiedCheckoutService: UnifiedCheckoutService,
              private readonly spinnerService: NgxSpinnerService,
              private renderer: Renderer2,
              private readonly scriptService: ScriptService,
              private readonly dialogRef: MatDialogRef<UnifiedCheckoutComponent>) { }


  @Output() onAuthorized: EventEmitter<IAuthorizationResponse> = new EventEmitter<IAuthorizationResponse>();

  paymentComplete: boolean = false;
  paymentCancelled: boolean = false;

  show: boolean = false;

  giftType!: GiftType;
  recurrence!: IBbRecurrence;
  giftTotal!: number;

  async ngOnInit() {
    this.logger.debug("unified-checkout.ngOnInit");

    this.logger.trace(`unified-checkout.ngOnInit | donationId: ${this.donationService.donationId}`);

    // Add a loading spinner
    await this.spinnerService.show(undefined, ConstantsService.defaultSpinnerOptions);

    // Subscribe to gift type
    this.donationService.giftType$.subscribe(giftType => {
      this.logger.trace(`unified-checkout.ngOnInit | giftType: `, giftType);
      this.giftType = giftType;
      if (this.giftType === GiftType.Recurring) {
        this.logger.trace(`unified-checkout.ngOnInit | recurrence: `, this.donationService.recurrence);
        this.recurrence = this.donationService.recurrence;
        this.giftTotal = this.giftService.giftTotal();
      }
    });

    // Get donor info
    const donor = this.donorService.donor;
    this.logger.trace(`unified-checkout.ngOnInit | donor: `, donor);

    const merchantId = await this.getMerchantId();

    // Get capture context and load buttons
    const captureContextResponse = await this.unifiedCheckoutService.getCaptureContext(
      merchantId,
      this.giftService.giftTotal().toString(),
      donor.address.addressLines,
      donor.address.city,
      donor.address.state.iso31662,
      donor.address.country.isoCode,
      donor.address.postalCode,
      donor.FirstName,
      donor.lastName,
      donor.emailAddress,
      donor.phone
    ).catch(err => {
      this.logger.error("Error getting capture context: ", err);

      this.handleError(err);
    });

    if (!captureContextResponse) {
      this.logger.debug('unified-checkout.ngOnInit | No capture context found')
      return;
    }

    this.logger.trace(`unified-checkout.ngOnInit | captureContextResponse: `, captureContextResponse);

    // Set a timeout based on the capture context exp
    const millisecondsTillExp = this.getTimeToExpiration(captureContextResponse);

    // Set a timeout to close the dialog
    this.createSessionTimeout(millisecondsTillExp);

    // Load the Unified Checkout script
    const scriptElement = this.scriptService.loadScript(this.renderer, captureContextResponse.ctx[0].data.clientLibrary);
    this.logger.trace(`unified-checkout.ngOnInit | scriptElement: `, scriptElement);
    scriptElement.onload = async () => {
      this.logger.debug("unified-checkout.ngOnInit | script loaded");
      await this.onScriptLoaded(captureContextResponse);
    };
  }

  onCancel() {
    this.logger.debug("unified-checkout.onCancel");
    this.paymentCancelled = true;
    this.dialogRef.close();
  }

  getTimeToExpiration(captureContextResponse: ICaptureContextResponse){
    this.logger.debug(`unified-checkout.getTimeout | exp: `, captureContextResponse.exp);
    const now = new Date().getTime();
    const exp = captureContextResponse.exp * 1000;
    const diff = exp - now;
    this.logger.debug(`unified-checkout.getTimeout | now: ${now} | exp: ${exp} | diff: ${diff}`)

    return diff;
  }

  createSessionTimeout(millisecondsTillExp: any) {
    this.logger.debug("unified-checkout.createSessionTimeout");
    setTimeout(() => {
      if (this.paymentComplete || this.paymentCancelled) {
        // If payment is complete or cancelled, do nothing
        this.logger.debug("unified-checkout.createSessionTimeout | Payment complete or cancelled");
        return;
      }

      this.logger.debug("unified-checkout.createSessionTimeout | Session expired");

      // Display a message to the user
      Swal.fire({
        title: 'Session Expired',
        text: "Your session has expired. Please click 'Secure Checkout' to try again.",
        icon: 'warning',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-swal-confirm-button'
        }
      }).then(() => {});
      this.onCancel();
    }, millisecondsTillExp);
  }

  async onScriptLoaded(captureContextResponse: ICaptureContextResponse): Promise<void> {
    this.logger.debug("unified-checkout.onScriptLoaded");

    const showArgs = {
      containers: {
        paymentSelection: "#buttonPaymentListContainer",
        paymentScreen: "#embeddedPaymentContainer"
      }
    };

    // Call Accept method in Unified Checkout js library that was loaded
    const accept = await Accept(captureContextResponse.transientTokenJwt);

    this.logger.trace('unified-checkout.onScriptLoaded | accept: ', accept);

    const up = await accept.unifiedPayments(false);

    this.logger.trace('unified-checkout.onScriptLoaded | unifiedPayments: ', up);

    this.show = true;

    await this.spinnerService.hide();
    const transientToken = await up.show(showArgs);

    this.logger.trace('unified-checkout.onScriptLoaded | transientToken: ', transientToken);

    await this.spinnerService.show(undefined, ConstantsService.defaultSpinnerOptions);

    try {
      // Single gift vs recurring
      let authResponse: IAuthorizationResponse;
      if (this.donationService.giftType === GiftType.Recurring) {
        authResponse = await this.processRecurringGift(transientToken);
      } else {
        authResponse = await this.processOneTimeGift(transientToken);
      }

      // Check for processor declined
      if (authResponse?.status !== 'AUTHORIZED') {
        // Flag the transaction as failed and add processor transaction id
        await this.donationService.updateOnlineGivingDonationWithFailure(this.donationService.donationId, authResponse.status, authResponse.id);

        Swal.fire({
          title: 'Payment Error',
          text: 'There was a problem processing your payment. Please try again, or contact Gift Processing at 858-246-1090 for assistance.',
          icon: 'error',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-swal-confirm-button'
          }
        }).then(() => {});
        this.spinnerService.hide().then(() => {});
        this.onCancel();
        return;
      }

      // Complete the donation
      this.donationService.completeOnlineGivingDonation(this.donationService.donationId, authResponse.id).then(() => {});

      this.onAuthorized.emit(authResponse);
      this.paymentComplete = true;

    } catch (err) {
      this.logger.error('unified-checkout.onScriptLoaded | Error: ', err);
      this.handleError(err);
    }

  }

  async processOneTimeGift(transientToken: string): Promise<IAuthorizationResponse>{
    this.logger.debug("unified-checkout.processOneTimeGift");

    const merchantId = await this.getMerchantId();

    const donor = this.donorService.donor;

    const result = await this.unifiedCheckoutService.authorizePayment(transientToken,
      merchantId,
      donor.address.addressLines,
      donor.address.city,
      donor.address.state.iso31662,
      donor.address.country.isoCode,
      donor.address.postalCode,
      donor.FirstName,
      donor.lastName,
      donor.emailAddress,
      donor.phone);

    this.logger.trace("unified-checkout.processOneTimeGift | authorizePaymentResult: ", result);

    const id = result.id;
    this.logger.debug("unified-checkout.processOneTimeGift | id: ", id);

    return result;
  }

  async processRecurringGift(transientToken: string): Promise<IAuthorizationResponse>{
    this.logger.debug("unified-checkout.processRecurringGift");

    const donor = this.donorService.donor;

    const result = await this.unifiedCheckoutService.createToken(transientToken,
      await this.getMerchantId(),
      donor.address.addressLines,
      donor.address.city,
      donor.address.state.iso31662,
      donor.address.country.isoCode,
      donor.address.postalCode,
      donor.FirstName,
      donor.lastName,
      donor.emailAddress,
      donor.phone
    );

    // Confirm that the token was authorized before we continue
    if (result.status !== 'AUTHORIZED') {
      this.logger.error("unified-checkout.processRecurringGift | Error creating customer token");
      return result;
    }

    // If the token was authorized, get customer token from authResponse
    const customerToken = result?.id;

    // If successful create plan
    const planResponse =
      await this.unifiedCheckoutService.createPlan(this.donorService.donor, this.giftService.giftTotal().toString(), this.donationService.recurrence, await this.getMerchantId());

    // If successful create subscription
    const subscriptionResponse =
      await this.unifiedCheckoutService.createSubscription(customerToken, planResponse.id, this.donorService.donor, this.donationService.recurrence, await this.getMerchantId());

    this.logger.trace("unified-checkout.processRecurringGift | subscriptionResponse: ", subscriptionResponse);

    return result;
  }

  handleError(err: any){
    // Update donation with failure information
    this.donationService.updateOnlineGivingDonationWithFailure(this.donationService.donationId, err.message, "").then(() => {});

    this.spinnerService.hide().then(() => {});

    // Display an error message to the user
    Swal.fire({
      title: 'Error',
      text: 'An error occurred. Please try again, or contact Gift Processing at 858-246-1090 to continue your donation.',
      icon: 'error',
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-swal-confirm-button'
      }
    }).then(() => {});
    // Close the dialog
    this.onCancel();
  }

  async getMerchantId(){
    this.logger.debug('unified-checkout.getMerchantId');
    // HACK: Better way of doing this?
    // Get Gifts
    const gifts = await this.giftService.getGifts();
    // Get merchant id
    const merchantId = this.donationService.getMerchantId(gifts);

    this.logger.debug('unified-checkout.getMerchantId | merchantId: ', merchantId);

    return merchantId;
  }

  protected readonly GiftType = GiftType;
  protected readonly RecurrenceType = RecurrenceType;
}
