import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from "@angular/forms";
import { GiftService } from "@data/services/gift.service";
import { SsoUserService } from "@data/services/sso-user.service";
import swal from "sweetalert2";

import { Event as NavigationEvent } from "@angular/router";
import { Router, NavigationStart } from "@angular/router";
import { filter } from "rxjs/operators";
import { DonationService } from "@data/services/donation.service";
import { PayrollDeductionService } from "@data/services/payroll-deduction.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ConstantsService } from "@core/services/constants.service";
import { LoggerService } from "@core/services/logger.service";
import { Title } from '@angular/platform-browser';
import {IGift} from "@interfaces/gift";


@Component({
  selector: 'app-payroll-deduction',
  templateUrl: './payroll-deduction.component.html',
  styleUrls: ['./payroll-deduction.component.css'],
  providers: [LoggerService]
})
export class PayrollDeductionComponent implements OnInit {

  constructor(public readonly giftService: GiftService,
              private readonly ssoUserService: SsoUserService,
              public readonly donationService: DonationService,
              private readonly payrollDeductionService: PayrollDeductionService,
              private readonly router: Router,
              private readonly spinnerService: NgxSpinnerService,
              private readonly logger: LoggerService,
              private readonly titleService: Title) {

    this.titleService.setTitle(`${ConstantsService.pageTitle} Your Info`);
    router.events.pipe(
      filter(
        (event: NavigationEvent) => {
          return (event instanceof NavigationStart);
        }
      )
    )
      .subscribe(
        (event: NavigationStart) => {
          if (event.restoredState) {
            // Navigate back to the review page as back will not work with SSO
            router.navigateByUrl("/review");
          }
        }
      );
  }

  readonly acknowledgementText: string = "UC San Diego will contact me if there are any questions about my contribution. My payment will be reflected on my UC San Diego Payroll stub and will satisfy the Internal Revenue Service Regulations regarding charitable gifts.";
  readonly payrollNotice: string = "Please be aware that, at this time, payroll deductions are ongoing, recurring gifts. To make a one-time gift, please give online using your credit/debit card or give us a call directly at (858) 534-1610";

  pageTitle: string = "Payroll Deduction";

  giftTotal: number = 0;
  gifts!: IGift[];
  isAcknowledged: boolean = false;
  payrollCycle: string;

  submitting: boolean = false;

  @ViewChild("f", { static: true }) form: NgForm;

  attempts: number = 0;



  /** Validate form and submit to gift processing */
  submit(): void {
    this.logger.debug('payroll-deduction.component.submit');
    this.submitting = true;

    // Check for sso user
    if (!this.donationService.ssoUser || !this.donationService.ssoUser.employeeId) {
      swal.fire({
        title: "An Error Occurred",
        text: "Your employee id could not be retrieved from single sign on. Please return to the previous page and try again, or contact Gift Processing to continue",
        icon: "error",
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-swal-confirm-button'
        }
      });
      return;
    }

    // Validate form
    if (this.form.invalid) {
      this.attempts++;
      return;
    }

    this.spinnerService.show(undefined, ConstantsService.defaultSpinnerOptions);

    this.payrollDeductionService.addPayrollDeduction(this.payrollCycle, this.giftService.gifts, this.donationService.ssoUser).then(
      data => {
        this.logger.trace('payroll-deduction.component.submit | addPayrollDeduction response: ', data);
        this.router.navigateByUrl("payrollsubmitted");
      }, error => {
        this.spinnerService.hide();
        swal.fire({
          title: "An Error Occurred",
          text: ConstantsService.processingErrorMessage,
          icon: "error",
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-swal-confirm-button'
          }
        });
        this.logger.fatal("An error occurred while attempting to add a Payroll Deduction", error);
      });
  }

  getGifts(): void {
    this.logger.debug('payroll-deduction.component.getGifts');
    this.gifts = this.giftService.gifts;
    this.logger.trace('payroll-deduction.component.getGifts | gifts: ', this.gifts);
    this.giftTotal = this.giftService.giftTotal();
  }

  ngOnInit() {
    this.logger.debug('payroll-deduction.component.ngOnInit');
    this.ssoUserService.getSsoUser().then(
      data => {
        this.donationService.ssoUser = data;
      });

    this.getGifts();

  }
}

