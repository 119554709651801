import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { AppConfigService } from "@core/services/app-config.service";

import { LoggerService } from "@core/services/logger.service";
import {ConstantsService} from "@core/services/constants.service";
import {IGift} from "@interfaces/gift";
import {ISsoUser} from "@interfaces/sso-user";

@Injectable({
  providedIn: 'root'
})
export class PayrollDeductionService {

  constructor(private readonly http: HttpClient,
              private readonly appConfigService: AppConfigService,
              private readonly logger: LoggerService) {  }

  private apiUrl = this.appConfigService.config.webApi.url + "/payrolldeduction";

  async addPayrollDeduction(payrollCycle: string, gifts: IGift[], ssoUser: ISsoUser): Promise<string> {
    this.logger.debug(`payroll-deduction.service.addPayrollDeduction | payrollCycle:  ${payrollCycle} | gifts: ${gifts} | ssoUser: ${JSON.stringify(ssoUser)}`);

    const payrollDeduction = {
      payrollCycle: payrollCycle,
      gifts: gifts,
      ssoUser: ssoUser
    };

    this.logger.trace("payroll-deduction.service.addPayrollDeduction | payrollDeduction: ", payrollDeduction);

    return this.http.post<string>(this.apiUrl, payrollDeduction, ConstantsService.httpOptions).pipe(
      tap(data => this.logger.trace(`payroll-deduction.service | results: `, data))).toPromise();

  }

}
