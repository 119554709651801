<div *ngIf="fund && fund.purposeDescription">
  <mat-expansion-panel (opened)="fundSelectionDescriptionPanelOpen = true"
                       (closed)="fundSelectionDescriptionPanelOpen = false"
                       [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <span class="emphasized-text">Fund Details</span>
      </mat-panel-title>
      <mat-panel-description *ngIf="!fundSelectionDescriptionPanelOpen">
        {{ (fund.purposeDescription.length > 29) ? (fund.purposeDescription | slice:0:26) + "..." : fund.purposeDescription }}
      </mat-panel-description>
    </mat-expansion-panel-header>
    {{ (fund.purposeDescription.length) > 255 ? (fund.purposeDescription | slice:255) + "..." : fund.purposeDescription }}
    <div class="mt-2" *ngIf="showLink">
      <app-appeal-code
        [appealCodeOnly]="false"
        (appealCodeSelectionChanged)="onAppealCodeChanged($event)">
      </app-appeal-code>
      <app-direct-link [fund]="fund"
                       [appealCode]="appealCode">
      </app-direct-link>
</div>
  </mat-expansion-panel>
</div>
