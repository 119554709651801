<div class="d-flex">
  <div class="pt-3 less-emphasized-text">
    Make This Gift
  </div>

</div>
<div *ngIf="form">
  <form [formGroup]="form">
    <div class="btn-group d-flex">
      <label id="giftTypeOneTime"
             [ngClass]="{
           'btn btn-selection w-100' : true,
           'active' : giftType.value === GiftType.OneTime}">
        <input type="radio" formControlName="giftType" [value]="GiftType.OneTime" required /> One Time
      </label>
      <label id="giftTypeRecurring"
             [ngClass]="{
           'btn btn-selection w-100' : true,
           'active' : giftType.value === GiftType.Recurring}">
        <input type="radio" formControlName="giftType" [value]="GiftType.Recurring" required /> Recurring
      </label>
      <label id="giftTypePledge"
             [ngClass]="{
           'btn btn-selection w-100' : true,
           'active' : giftType.value === GiftType.PledgePayment}">
        <input type="radio" formControlName="giftType" [value]="GiftType.PledgePayment" required /> Pledge Payment
      </label>
    </div>
  </form>
</div>
<div class="alert alert-danger mt-md-1" *ngIf="submitting && form.invalid">
  Please select a Gift Type
</div>
