export interface IState {
  id: string;
  description: string;
  abbreviation: string;
  iso31662: string;
}

export class State implements IState {
  id: string;
  description: string;
  abbreviation: string;
  iso31662: string;

  /** Returns state abbreviation */
  public toString() {
    return this.abbreviation;
  }

  constructor(id: string, description: string, abbreviation: string, iso31662: string) {
    this.id = id;
    this.description = description;
    this.abbreviation = abbreviation;
    this.iso31662 = iso31662;
  }
}
