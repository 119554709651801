import { Injectable } from '@angular/core';
import {LoggerService} from "@core/services/logger.service";
import {LocalStorageService} from "@core/services/local-storage.service";
import {Donor} from "@interfaces/donor";
import {ConstantsService} from "@core/services/constants.service";

@Injectable({
  providedIn: 'root'
})
export class DonorService {

  constructor(private readonly logger: LoggerService,
              private readonly localStorage: LocalStorageService) { }


  get donor(): Donor {
    return this.localStorage.getItem<Donor>(ConstantsService.localDonorKey);
  }
  set donor(value: Donor) {
    this.localStorage.setItem(ConstantsService.localDonorKey, value);
  }

  get isCorporate(): boolean {
    return this.localStorage.getItem(ConstantsService.localIsCorporateKey);
  }
  set isCorporate(value: boolean) {
    this.localStorage.setItem(ConstantsService.localIsCorporateKey, value);
  }

  get isAnonymous(): boolean {
    return this.localStorage.getItem(ConstantsService.localIsAnonymousKey);
  }
  set isAnonymous(value: boolean) {
    this.localStorage.setItem(ConstantsService.localIsAnonymousKey, value);
  }

}
