<app-progress-bar [progressStep]="3"></app-progress-bar>

<div class='col-md-6 offset-md-3'>

  <div class="d-flex">
    <h2 id="lblAboutYou" class="gray pb-2">{{pageTitle}}</h2>
  </div>
  <div class="mb-2 mt-md-2">
    <mat-checkbox id="chkIsCorporate" class="checkLabel" [(ngModel)]="isCorporate" (change)="onCorporateChange()">Donation being made on behalf of a company or organization</mat-checkbox>
  </div>

  <form [formGroup]="yourInfoForm" (ngSubmit)="onSubmit()">

    <div class="d-lg-flex" *ngIf="isCorporate">
      <div class="w-100 form-group">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Name of the Company to be Receipted</mat-label>
          <input matInput formControlName="organizationName" name="companyName" id="companyName" />
          <mat-error *ngIf="organizationName.errors?.required && (organizationName.touched || organizationName.dirty)">Organization name is required if box is checked</mat-error>
          <mat-error *ngIf="organizationName.errors?.maxlength">{{ ConstantsService.maxLengthMessage }} is {{ ConstantsService.lastNameCharacterLimit }} characters</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div class="d-lg-flex">
      <div class="w-100 form-group">
        <mat-form-field class="w-100 pr-md-2" appearance="outline">
          <mat-label>First Name</mat-label>
          <input matInput formControlName="FirstName" id="firstName" /></mat-form-field>
        <mat-error *ngIf="firstName.errors?.required && (firstName.touched || firstName.dirty)">First name is required</mat-error>
        <mat-error *ngIf="firstName.errors?.maxlength">{{ ConstantsService.maxLengthMessage }} is {{ ConstantsService.firstNameCharacterLimit }} characters</mat-error>
        <mat-error *ngIf="firstName.hasError('invalidCharacters')">{{ConstantsService.invalidCharactersMessage}}</mat-error>
      </div>
      <div class="w-100 form-group">
        <mat-form-field class="w-100" appearance="outline">
          <mat-label>Last Name</mat-label>
          <input matInput formControlName="lastName" id="lastName" /></mat-form-field>
        <mat-error *ngIf="lastName.errors?.required && (lastName.touched || lastName.dirty)">Last name is required</mat-error>
        <mat-error *ngIf="lastName.errors?.maxlength">{{ ConstantsService.maxLengthMessage }} is {{ ConstantsService.lastNameCharacterLimit }} characters</mat-error>
        <mat-error *ngIf="lastName.hasError('invalidCharacters')">{{ConstantsService.invalidCharactersMessage}}</mat-error>
      </div>
    </div>

    <div class="d-lg-flex">
      <div class="w-100 form-group">
        <mat-form-field class="w-100 pr-md-2"  appearance="outline">
          <mat-label>Phone Number</mat-label>
          <input matInput mask="(000) 000-00009" formControlName="phone" name="phone" id="phone"/>
          <mat-error *ngIf="phone.errors?.required && (phone.touched || phone.dirty)">Phone is required</mat-error>
        </mat-form-field>
      </div>
      <div class="w-100 form-group">
        <mat-form-field class="w-100"  appearance="outline">
          <mat-label>Email Address</mat-label>
          <input type="email" matInput formControlName="emailAddress" name="email" id="email" />
          <mat-error *ngIf="emailAddress.invalid">Not a valid email address</mat-error>
        </mat-form-field>
      </div>
    </div>

    <app-address [form]="addressForm"
                 *ngIf="showAddress"
                 (stateChanged)="onStateChange($event)">
    </app-address>

    <div class="mb-md-3 mb-1">
      <mat-checkbox id="chkAnonymous" class="checkLabel" formControlName="isAnonymous">Make this gift anonymous</mat-checkbox>
    </div>



    <div *ngIf="attempts >= 3 && yourInfoForm.invalid">
      <app-validation-failure [form]="yourInfoForm"></app-validation-failure>
    </div>
    <div *ngIf="attempts >= 3 && addressForm.invalid">
      <app-validation-failure [form]="addressForm"></app-validation-failure>
    </div>
    <div class="btn-group d-flex">
      <button id="btnReviewGifts" type="button" class="btn btn-navigation w-100" (click)="onReview()">
        <i class="fa fa-chevron-left"></i> Review Gifts
      </button>
      <button id="btnSecurePayment" type="submit" class="btn btn-navigation w-100" *ngIf="!armedForces" [disabled]="!enableCheckout">
        <i class="fa fa-lock"></i> Secure Checkout
      </button>
    </div>
    <div class="mt-4">
      <ngx-invisible-recaptcha #captchaElement
                               [siteKey]="captchaSiteKey"
                               badge="inline"
                               (ready)="onCaptchaReady()"
                               (load)="onCaptchaLoad()"
                               (error)="onCaptchaError($event)"
                               (success)="onCaptchaSuccess($event)">
      </ngx-invisible-recaptcha>
    </div>
    <div id="armedForcesMsg" class="mt-3 info-box" *ngIf="armedForces">
      <span [innerHtml]="armedForcesMsg"></span>
    </div>
  </form>

</div>


