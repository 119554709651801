<div class="pt-2 pb-2">
  <mat-checkbox id="chkAppealCode" class="checkLabel" [(ngModel)]="hasAppealCode" (change)="onHasAppealCodeChanged($event)">
    {{ appealCodeOnly ? 'Appeal Code ONLY' : 'Include Appeal Code' }}
  </mat-checkbox>
</div>

<div class="form-group d-flex" *ngIf="hasAppealCode && appealCodes">
  <div class="w-100">
    <ng-select id="selectAppealCode"
               [items]="appealCodes"
               bindLabel="name"
               bindValue="id"
               placeholder="Appeal Code"
               (change)="onAppealCodeChange($event)">
    </ng-select>
  </div>
  
</div>
<div class="mt-2" *ngIf="appealCodeOnly && hasAppealCode && selectedAppealCode">
  <app-direct-link [appealCode]="selectedAppealCode">

  </app-direct-link>
</div>
