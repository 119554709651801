import { Injectable } from '@angular/core';
import { NGXLogger, NgxLoggerLevel } from "ngx-logger";
import { AppConfigService } from "@core/services/app-config.service";
import { IAppConfig } from "@core/interfaces/app-config";


@Injectable({
  providedIn: 'root'
})
export class LoggerService {

  private apiUrl: string;
  private isSet: boolean = false;

  constructor(private logger: NGXLogger,
    private appConfigService: AppConfigService) {
    this.updateLoggerSettings(this.appConfigService.config)
  }

  private updateLoggerSettings(settings: IAppConfig) {

    const localLogLevel = NgxLoggerLevel[settings.logging.localLogLevel];
    const serverLogLevel = NgxLoggerLevel[settings.logging.serverLogLevel];
    this.apiUrl = settings.logging.logServerUrl;

    // Ger current config and check it against settings before updating
    let config = this.logger.getConfigSnapshot();
    if (config.serverLogLevel !== serverLogLevel ||
      config.level !== localLogLevel ||
      config.serverLoggingUrl !== this.apiUrl) {

      this.logger.info("logger.service.updateLoggerSettings | Update Logger settings");
      this.logger.updateConfig({
        level: localLogLevel,
        serverLogLevel: serverLogLevel,
        serverLoggingUrl: this.apiUrl
      });

      this.isSet = true;

      this.logger.info(`logger.service.updateLoggerSettings | Local Log Level: ${localLogLevel}`);
      this.logger.info(`logger.service.updateLoggerSettings | Server Log Level: ${serverLogLevel}`);
      this.logger.info(`logger.service.updateLoggerSettings | Log API: ${this.apiUrl}`);
    } else {
      this.logger.info("logger.service.updateLoggerSettings | No changes to logger detected");
    }

  }

  public async loggerReady(): Promise<boolean> {
    try {
      this.logger.info(`logger.service.loggerReady | Logger Ready: ${this.isSet}`);
      // If the logger is not setup yet attempt to set it up now
      if (!this.isSet) {
        this.updateLoggerSettings(this.appConfigService.config);
        this.logger.info(`logger.service.loggerReady | Logger Ready: ${this.isSet}`);
      }
      return this.isSet;
    } catch (error) {
      this.logger.error(`logger.service.loggerReady | Error in Logger Ready: ${error}`);
      throw error;
    }
  }

  /**
   * Creates a string out of message to be logged and a stringified object
   * @param msg Message to be logged
   */
  private getMessage(msg: string, obj?: any): string {
    // HACK: Find a way to include object even if null/undefined if it is actually passed vs just not passed
    return `${msg} ${(obj) ? JSON.stringify(obj): ""}`;
  }

  trace(msg: string, obj?: any): void {
    this.logger.trace(this.getMessage(msg, obj));
  }
  debug(msg: string, obj?: any): void {
    this.logger.debug(this.getMessage(msg, obj));
  }


  info(msg: string, obj?: any): void {
    this.logger.info(this.getMessage(msg, obj));
  }

  error(msg: string, obj?: any): void {
    this.logger.error(this.getMessage(msg, obj));
  }

  fatal(msg: string, obj?: any): void {
    this.logger.fatal(this.getMessage(msg, obj));
  }


}
