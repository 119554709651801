<div class="d-flex">
  <div class="input-group">
    <input id="fundSearch" class="form-control" type="text" #designationSearch [(ngModel)]="searchText" [placeholder]="placeholderText" (keyup.enter)="onSearch(designationSearch.value)" />
    <div class="input-group-append">
      <button class="btn btn-navigation btn-search" type="button" (click)="onSearch(designationSearch.value)"><i class="fa fa-search"></i></button>
    </div>
  </div>
</div>

<div class="d-flex">
  <div *ngIf="searching" class="w-100 pt-3">
    <span class="emphasized-text">Searching...</span>
  </div>
  <div *ngIf="noResults" class="w-100 pt-3">
    <span class="emphasized-text">No Results Found...</span>
    <div class="pt-2 info-box">Having trouble finding the fund you’re looking for? Try searching by a portion of the fund name, just the fund number, or contact us at (858) 534-1610 or <a href="mailto: giving@ucsd.edu">giving&#64;ucsd.edu</a> for help.</div>
  </div>
  <div *ngIf="searchResults" class="w-100 pt-3">
    <div class="pb-1">
      Please Select a Fund:
    </div>
    <ng-select id="selectFundIdFromSearch"
               [items]="searchResults"
               bindLabel="publicName"
               placeholder="-- Search Results --"
               (change)="searchResultSelected($event)"
               [(ngModel)]="selectedFund"
    >
    </ng-select>
  </div>
</div>

<div class="pt-3" *ngIf="selectedFund">
  <app-fund-description
    [fund]=selectedFund
    [showLink]="showLink">
  </app-fund-description>
</div>
