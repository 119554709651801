import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfigService } from "@core/services/app-config.service";
import { IDesignation } from "@interfaces/designation";
import { LoggerService } from "@core/services/logger.service";


@Injectable({
  providedIn: 'root'
})
export class DesignationService {

  constructor(private readonly http: HttpClient,
              private readonly appConfigService: AppConfigService,
              private readonly logger: LoggerService) {  }

  private designationApiUrl: string = this.appConfigService.config.webApi.url + "/designation";

  async getDesignation(id: string): Promise<IDesignation> {
    this.logger.debug('designation.service.getDesignation | id: ', id);

    const options = { params: new HttpParams().set("id", id) };
    return this.http.get<IDesignation>(this.designationApiUrl, options).toPromise();
  }

}
