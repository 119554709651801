import { NgModule } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import { YourInfoComponent } from "@features/your-info/your-info.component";
import { GiftReviewComponent } from "@features//gift-review/gift-review.component";
import { GiftAddComponent } from "@features/gift-add/gift-add.component";
import { ConfirmationComponent } from "@features/confirmation/confirmation.component";
import { PayrollDeductionComponent } from "@features/payroll-deduction/payroll-deduction.component";
import { MatchingGiftComponent } from "@features/matching-gift/matching-gift.component";
import { GiftAddReferralComponent } from "@features/gift-add-referral/gift-add-referral.component";
import { GiftEditComponent } from "@features//gift-edit/gift-edit.component";
import { LinkGeneratorComponent } from "@features/link-generator/link-generator.component";

const routes: Routes = [
  { path: "confirmation/:t", component: ConfirmationComponent },
  { path: "confirmation", component: ConfirmationComponent },
  { path: "payrollsubmitted", component: ConfirmationComponent },
  { path: 'payrollconfirmation', component: PayrollDeductionComponent },
  { path: 'review', component: GiftReviewComponent },
  { path: 'your-info', component: YourInfoComponent },
  { path: "edit-gift/:id", component: GiftEditComponent },
  { path: "add-gift/:appealId", component: GiftAddComponent },
  { path: "add-gift", component: GiftAddComponent },
  { path: "gift-referral/:id/:appealId", component: GiftAddReferralComponent },
  { path: "gift-referral/:id", component: GiftAddReferralComponent },
  { path: "matching-gift", component: MatchingGiftComponent },
  { path: "link-generator", component: LinkGeneratorComponent },
  { path: '', component: GiftReviewComponent },
  { path: '**', component: GiftReviewComponent }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
